<template>
  <div class="new-date-picker-25">
    <date-range-picker
      v-model="dateRange"
      :disabled="unit_id ? false : true"
      @update="updateValues"
      :auto-apply="true"
      :ranges="false"
      :single-date-picker="true"
      :maxDate="getCurrentTime()"
    >
      <template #input="picker">
        <span v-if="dateRange && dateRange.startDate">
          {{
            `${picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""}`
          }}
          <!-- {{
          `${picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "}`
        }} -->
        </span>
        <span v-else class="place-holder-date"
          >{{ $t("unit.SelectDateRange") }}
        </span>
      </template>
      <!--    date slot-->
      <template #date="data">
        <span class="small">{{ data.date | dateCell }}</span>
      </template>
      <!--    ranges (new slot syntax) -->
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="range in ranges.ranges"
              :key="range.key"
              @click="ranges.clickRange(range.value)"
            >
              <b>{{ range.label }}</b>
            </li>
          </ul>
        </div>
      </template>
      <!--    footer slot-->
    </date-range-picker>
  </div>
</template>

<script>
import constants from "@/utils/constants";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  name: "SlotsDemo",
  components: { DateRangePicker },
  data() {
    return {
      dateRange:
        this.value && this.value.startDate
          ? { startDate: this.value.startDate, endDate: this.value.startDate }
          : {
              startDate: this.getCurrentTime(),
              endDate: this.getCurrentTime()
            },
      customRanges: {
        Today: [new Date(), new Date()],
        Yesterday: [this.getYesterday(), this.getYesterday()]
      }
    };
  },

  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  props: ["selectedMenu", "unit_id", "value"],
  watch: {
    unit_id(val) {
      if (!val) {
        this.dateRange = {
          startDate: this.getCurrentTime(),
          endDate: this.getCurrentTime()
        };
        this.$emit("input", this.dateRange);
      }
    },
    value(newVal, oldVal) {
      this.dateRange = newVal &&
        newVal.startDate && {
          startDate: this.value.startDate,
          endDate: this.value.endDate || this.value.startDate
        };
    }
  },
  mounted() {
    if (this.value && this.value.startDate) {
      this.updateValues({
        startDate: this.getCurrentTime(this.value.startDate),
        endDate: this.getCurrentTime(this.value.startDate)
      });
    } else {
      this.updateValues({
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      });
    }
  },
  methods: {
    updateValues(e) {
      // Set the start date to the current time
      if (this.dateRange.startDate) {
        const startDate = moment(this.dateRange.startDate);

        startDate.hours(0);
        startDate.minutes(0);
        startDate.seconds(0);

        this.dateRange.startDate = startDate;
      }

      this.dateRange.startDate = this.convertDatePerTimezone(
        this.dateRange.startDate
      );

      if (this.dateRange.endDate) {
        let endDate = moment(this.dateRange.endDate);
        const now = moment();

        const isToday = endDate.isSame(now, "day");

        if (isToday) {
          // Set current time for Today, This Week, This Month
          endDate.hours(now.hours());
          endDate.minutes(now.minutes());
          endDate.seconds(now.seconds());
        }

        if (!isToday) {
          // Set to 23:59:59 for Yesterday, Last Week, Last Month
          endDate.hours(23);
          endDate.minutes(59);
          endDate.seconds(59);
        }

        this.dateRange.endDate = endDate;
      }

      this.dateRange.endDate = this.convertDatePerTimezone(
        this.dateRange.endDate
      );

      this.$emit("input", this.dateRange);
    },
    getYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday;
    },
    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_FORMAT);
    }
  }
};
</script>

<style lang="scss">
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
// .reportrange-text {
//   background: transparent !important;
//   padding: 9px 10px !important;
//   border: 1px solid #d8d6de !important;
//   margin-bottom: 10px;
// }
// .daterangepicker.openscenter {
//   left: 23px !important;
// }
// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
// }
</style>
<style lang="scss">
@media only screen and (max-width: 666px) {
  .new-date-picker-25 {
    .daterangepicker .ltr .show-calendar .show-ranges .opensleft .linked {
      left: 0 !important;
      transform: translate(0%) !important;
    }
    .daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked {
      left: 0 !important;
      transform: translate(0%) !important;
    }
  }
}
</style>
